<template>
  <div class="user-manage">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'userManage',
  data () {
    return {}
  }
}
</script>
<style lang="less" scoped>
.user-manage {
  width: 100%;
  height: 100%;
}
</style>
